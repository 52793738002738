.week_filter
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.controls
{
    display: flex;
    gap: 5px;
}

.controls button, .controls span
{
    border: 1px solid var(--borderGrey);
    border-radius: 5px;

    background: transparent;

    font-size: 18px;
    padding: 5px;
}

.controls button
{
    width: 35px;
    cursor: pointer;
}

.controls button:hover
{
    border-color: var(--btnColor);
}

.controls button:active
{
    background: linear-gradient(180deg, #DAF1D9 30%, rgba(218, 241, 217, 0) 100%);
}

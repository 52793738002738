.statistics_container
{
    display: flex;
    flex-direction: column;

    gap: 30px;

    width: 100%;
}

.controls
{
    display: flex;
    justify-content: center;
    gap: 5px;

    width: 100%;
}

.controls span
{
    width: 150px;
    text-align: center;
}

.controls button, .controls span
{
    border: 1px solid var(--borderGrey);
    border-radius: 5px;

    background: transparent;

    font-size: 18px;
    padding: 5px;
}

.controls button
{
    width: 35px;
    cursor: pointer;
}

.controls button:hover
{
    border-color: var(--btnColor);
}

.controls button:active
{
    background: linear-gradient(180deg, #DAF1D9 30%, rgba(218, 241, 217, 0) 100%);
}


.stats_tables_container
{
    display: flex;
    width: 100%;
    gap: 10px;
}

.table_content
{
    display: flex;
    flex-direction: column;

    flex: 1;
    gap: 5px;
}

.table_content table
{
    text-align: left;
}

.table_content thead
{
    background: var(--borderGrey);
    border-radius: .5rem;
}

.table_content thead tr th:nth-child(1), .table_content tbody td:nth-child(1)
{
    flex: 2;
}

.table_content tbody tr, .table_content thead tr
{
    padding: 5px;
}

.header
{
    font-weight: 600;
    font-size: 24px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .stats_tables_container
    {
        flex-direction: column;
    }
}

.shifts_container
{
    display: flex;
    flex-direction: column;
    position: relative;

    width: 100%;

    gap: 10px;
}

.shift_list
{
    display: flex;
    flex-direction: column;
}

.shift_card
{
    width: 150px;
    border: 1px solid green;

    position: relative;
}

.shift_list_header
{
    display: flex;
}

.notification_button
{
    display: flex;
    position: absolute;

    z-index: 5;
}

.user_card
{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    gap: 5px;
}

.delete_user_btn
{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.delete_user_btn:hover
{
    color: var(--error);
}

.search
{
    display: flex;
    align-items: center;

    padding: 5px;
    gap: 5px;

    border: 2px solid var(--borderGrey);
    border-radius: 5px;
}

.search input
{
    all: unset;

    width: 100%;
}

.search:focus-within
{
    border-color: var(--btnColor);
}

.container
{
    display: flex;
    flex-direction: column;

    background: #fff;

    width: 260px;

    gap: 10px;
}

.preference_container
{
    display: flex;
    flex-direction: column;

    gap: 10px;

    padding: 10px;

    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #fff;
}

.mobile_container
{
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
}

.mobile_container a
{
    padding: 4px 8px;
    font-size: 18px;
}

.preference_container h2
{
    text-align: center;

    padding: 0;
    margin: 0;
}

.preference_container label
{
    font-size: 18px;
}

.preference_container input
{
    width: 18px;
    height: 18px;
}

.controls
{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.shift_modification_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: absolute;

    top: 100%;

    padding: 0.5rem;

    border: 1px solid var(--borderGrey);
    border-radius: 10px;
    background: #fff;

    z-index: 5;

    color: #000;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.controls_container
{
    display: flex;
    gap: 10px;
}

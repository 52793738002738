.shifts_mobile_container
{
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 94vw;

    position: relative;

    padding-bottom: 50px;
}

.days_list
{
    display: flex;
    width: 100%;
    gap: 5px;
}

.day_data_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
}

.day_card
{
    display: flex;
    flex-direction: column;

    text-align: center;

    flex: 1;
    padding: 5px;
    border: 2px solid var(--borderGrey);
    border-radius: 1rem;
}

.today
{
    border-color: #000;
}

.selected_day
{
    border-color: var(--btnColor) !important;
    color: #fff;
}

.employees_list
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.additional_info_controls
{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;

    font-weight: 600;
    color: var(--btnColor);

    padding: 5px;
    box-sizing: border-box;
}

.additional_info_controls:hover
{
    color: var(--btnColorHover);
    border-color: var(--btnColorHover);
}

.holiday
{
    border-color: #ff0800;
    color: #ff0800;
    background: transparent !important;
}

.bottom_menu
{
    display: flex;
    justify-content: center;

    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;

    position: fixed;

    background: #fff;
    box-shadow: 0 0 20px rgba(212,216,232);
    padding: 10px;

    box-sizing: border-box;

    z-index: 3;
}

.bottom_menu a
{
    padding: 5px;
}

.shifts_mobile_controls
{
    display: flex;
    gap: 10px;
    justify-content: center;
}

.incomplete
{
    background: var(--error)
}

.halfComplete
{
    background: var(--warning)
}

.complete
{
    background: var(--success)
}

.unknown
{
    background: transparent;
}

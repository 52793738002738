.modal_top
{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.modal_controls
{
    display: flex;
    gap: 10px;
}


.selected_days_list
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
}

.selected_days_list span
{
    border: 1px solid var(--btnColor);
    border-radius: 8px;

    padding: 5px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .modal_top
    {
        gap: 10px;
        text-align: center;
    }
}

.card_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 100%;
    height: 100px;

    gap: 5px;

    border: 1px solid var(--btnColor);
    border-radius: 10px;

    color: #fff;

    cursor: pointer;

    padding: 5px;
    box-sizing: border-box;
}

.card_container:hover
{
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.card_selected
{
    background: linear-gradient(180deg, #DAF1D9 0%, rgba(218, 241, 217, 0) 100%) !important;
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);

    color: #000;
}

.card_control
{
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.card_controls_absolute
{
    justify-content: space-between;

    position: absolute;
    bottom: 0;

    margin: 5px;
}

.card_controls_absolute a
{
    margin-left: 2px;
    margin-right: 2px;
}


.card_control a
{
    box-sizing: border-box;
    padding: 5px;

    border-radius: 8px;
}

.none_shifts
{
    color: #000;
}

.morning
{
    background: var(--morning);
}

.day
{
    background: var(--day);
}

.evening
{
    background: var(--evening);
}

.vacation
{
    background: var(--vacation);
}

.sickLeave
{
    background: var(--sickLeave);
}

.custom
{
    background: var(--custom);
}

.weekend_check
{
    background: linear-gradient(180deg, rgba(245,166,139,1) 50%, rgba(90,90,128,1) 50%);
}

.shift_content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    gap: 10px;
}

.shift_info
{
    display: flex;
    flex-direction: column;
}

.weekends_shift_info
{
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.weekend_morning, .weekend_evening
{
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 50%;
}

.card_main_container
{
    display: flex;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.employee_name_mobile
{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 30%;
    gap: 5px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .card_container
    {
        width: 70%;
    }
}

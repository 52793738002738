.edit_container
{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;


    width: 600px;
    height: 600px;
    gap: 10px;
}

.center
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    gap: 5px;
}


.comments, .notes
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;
    height: 50%;
}

.text_editor_container
{
    display: flex;
    position: relative;

    width: 100%;
    height: 100%;
}

.text_editor
{
    position: absolute;
    width: 100%;
    height: 70%;
}


.subtitle
{
    text-align: center;

    font-weight: 600;
    font-size: 18px;
    color: var(--error);
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .edit_container
    {
        width: fit-content;
        overflow-y: scroll;
        position: relative;
    }

    .comments, .notes
    {
        height: 250px;
    }

    .text_editor
    {
        height: 50%;
    }
}

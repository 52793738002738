:root
{
    --background: rgb(22, 22, 26);
    --navbarColor: #3AA935;
    --btnColor: #297E26;
    --btnColorDisabled: #cccccc;
    --btnColorHover: #3AA935;

    --error: #C6394A;
    --warning: #E6BD19;
    --success: #1EA966;

    --errorHover: #8a2733;

   --borderGrey: #D1D1D1;

    --morning: #F5A68B;
    --day: #73B5B1;
    --evening: #5A5A80;
    --vacation: #F26419;
    --sickLeave: #4F6467;
    --custom: #773E7F;



}

.custom_shift_selector_container
{
    display: flex;
    flex-direction: column;

    text-align: left;
}

.select_filter
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.label
{
    font-weight: 500;
    font-size: 15px;
/*    color: var(--filterLabelColor);*/
    color: var(--btnColor);
}

.select
{
    display: flex;
    position: relative;
    gap: 5px;

    width: 100%;
    height: 32px;

    border-radius: 5px;
    background: transparent;
    padding: 7px 0.75rem;

    border: 2px solid var(--borderGrey);

    cursor: pointer;

    justify-content: space-between;

    box-sizing: border-box;
}

.dropdown_arrow
{
    display: flex;
    align-items: center;
    height: 100%;
}

.selected_options_container
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.selected_option
{
    display: flex;
    align-items: center;
    border-radius: 2rem;
    border: 1px solid var(--btnColor);

    padding: 0.25rem 0.5rem;

    gap: 8px;

    font-weight: 400;
    font-size: 12px;
    box-sizing: border-box;

    width: fit-content;
}

.selected_option span
{
    color: #000;
}

.selected_option svg
{
    color: var(--btnColor);
    width: 14px;
    height: 14px;
}

.delete_selected
{
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.multi_clicked
{
    border-color: var(--btnColor);
    border-radius:5px 5px 0 0;
}

.multi_selector
{
    display: flex;
    flex-direction: column;
    position: absolute;

    width: 100%;
    max-height: 450px;

    top: 100%;
    left: -1.5px;
    right: 0;

    overflow-y: scroll;

    background: #fff;

    border: inherit;
    border-top: none;
    border-radius: 0 0 5px 5px;

    z-index: 5;
}

.multi_selector input
{
    all: unset;
}


.multi_selector input, .option
{
    padding: 7px 0.75rem;
    font-size: 14px;
}

.option:hover, .selected
{
    background: var(--borderGrey);
}

.placeholder
{
    display: flex;
    align-items: center;
}

.multi_selected_options_list
{
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    gap: 5px;
}

.chosen
{
    color: #757575;
}

.disabled
{
    background: var(--btnColorDisabled);
    border-color: var(--btnColorDisabled);

    cursor: default;

    pointer-events: none;
}

.weekend_check_container
{
    display: flex;
    flex-direction: column;
}

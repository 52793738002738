.whole_week_container
{
    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    gap: 10px;
}

.filters_controls
{
    display: flex;
}

.days_list
{
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
}

.whole_week_container a
{
    font-size: 3.5vw;
}

.multiple_shifts_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    width: 600px;

    gap: 10px;
    position: relative;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .multiple_shifts_container
    {
        width: fit-content;
    }
}

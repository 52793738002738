.shifts_container
{
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.shifts_controls
{
    display: flex;
    justify-content: center;

    gap: 10px;
}

.shifts_list
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 10px;
}

.shift_card_wrapper
{
    width: 200px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .shifts_list
    {
        justify-content: center;
    }

    .shift_card_wrapper
    {
        width: 170px;
    }
}

header
{
    display: flex;
    justify-content: center;

    width: 100%;

    background: var(--navbarColor);

    margin-bottom: 50px;
}

.navbar_container
{
    display: flex;
    align-items: center;
    width: 1470px;

    padding: 10px;
    gap: 50px;
}

.logo svg
{
    width: 104px;
    height: 23px;
    fill: #fff;
}

.controls_container, .nav_menu_container, .nav_menu
{
    display: flex;
    width: 100%;
}

.nav_links, .nav_user_controls
{
    display: flex;
    align-items: center;

    gap: 8px;
    width: 100%;
}

.nav_links a:hover
{
    background: var(--btnColor);
    color: #fff;
}

.nav_links a svg
{
    width: 20px;
    height: 20px;
}

.nav_links a:nth-child(2) svg
{
    width: 25px;
    height: 25px;
}

.nav_user_controls
{
    justify-content: flex-end;
    gap: 58px;
}

.user_lang_container
{
    display: flex;
    gap: 20px;
}

.language_selections
{
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;

    padding: 4px 8px;

    color: #fff;
}

.language_selections hr
{
    height: 15px;
}

.language_selections button
{
    all: unset;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
}

.selected_language
{
    font-weight: 600 !important;
    text-decoration: underline !important;
}

.controls_container span
{
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    cursor: pointer;
}

.controls_container span svg
{
    width: 30px;
    height: 30px;

    color: #fff;
}

.user_name
{
    display: flex !important;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{

    .nav_user_controls
    {
        flex-direction: column;
        align-items: flex-end;

        gap: 10px;
    }

    .nav_menu_container:not(.open)
    {
        display: none;
    }

    .navbar_container a, .controls_container span
    {
        z-index: 5;
    }

    .navbar_container
    {
        touch-action: none;
    }

    .controls_container span
    {
        display: flex;
        cursor: pointer;
    }

    .nav_menu_container
    {
        position: fixed;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: rgba(0,0,0,.8);

        z-index: 4;
    }

    .nav_menu
    {
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        top: 60px;

        padding: 0.625rem;

        width: 100%;
        height: 100%;

        gap: 10px;
    }

    .nav_links
    {
        flex-direction: column;
        align-items: flex-end;
    }

    .nav_user_controls option
    {
        background: #fff;
        color: #000;
    }

    .nav_links a, .nav_user_controls select
    {
        color: #fff;

        border: 2px solid #fff;
    }
}

.filters
{
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 20px;
}

.search_controls_filters
{
    display: flex;
    justify-content: space-between;
}

.filters_controls
{
    display: flex;
    gap: 10px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .search_controls_filters
    {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .filters_controls
    {
        justify-content: center;
/*        flex-direction: column;*/
    }
}


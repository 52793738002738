.delete_container
{
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;

    padding: 1rem;
    width: 600px;
}

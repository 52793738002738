.custom_shift_container
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    gap: 10px;
    padding: 1rem;
}

.custom_shift_container label
{
    display: flex;
    flex-direction: column;
    text-align: center;

    width: 100%;

    gap: 5px;
}

.controls
{
    display: flex;

    gap: 10px;
}


.time_selectors
{
    display: flex;
    gap: 10px;
}

.half_checkbox
{
    display: flex;
    flex-direction: row !important;
    align-items: center;
}

.half_checkbox input
{
    width: 25px;
    height: 25px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .custom_shift_container
    {
        width: fit-content;
    }

    .time_selectors
    {
        flex-direction: column;
    }
}

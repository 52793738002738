.not_saved_container
{
    display: flex;
    flex-direction: column;
    padding: 1rem;

    text-align: center;

    gap: 10px;
}

.header
{
    font-weight: 600;
    font-size: 24px;
}

.warning_messages
{
    display: flex;
    flex-direction: column;
}

.modal_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;

    width: 100%;
}

.card_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 1rem;

    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    cursor: pointer;

    gap: 16px;

    font-size: 16px;

    box-sizing: border-box;
}

.header
{
    font-weight: 600;
    font-size: 32px;
}

.card_info
{
    display: flex;
    text-align: left;
    gap: 5px;

    height: 100%;
}

.additional_info
{
    display: flex;
    flex-direction: column;

    padding-top: 5px;
}

.week_day
{
    font-weight: 600;
}

.month
{
    opacity: .7;
}

.additional_info_controls
{
    display: flex;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 8px;
    width: 100%;

    font-weight: 600;
    color: var(--btnColor);

    padding: 3px;
    box-sizing: border-box;
}

.additional_info_controls:hover
{
    color: var(--btnColor);
    border-color: var(--btnColor);
}

.card_container:hover
{
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.card_selected
{
    background: linear-gradient(180deg, #DAF1D9 0%, rgba(218, 241, 217, 0) 100%) !important;
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.card_today
{
    border-color: var(--btnColor);
}

.holiday
{
    border-color: #ff0800 !important;
    color: #ff0800;
    background: transparent !important;
}

.users_shift_container
{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.shift
{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 50%;

    width: 30px;
    height: 30px;

    box-sizing: border-box;

    color: #fff;

    font-weight: 600;
}

.incomplete
{
    background: linear-gradient(180deg, var(--error) 25%, rgba(218, 241, 217, 0) 70%);
}

.halfComplete
{
    background: linear-gradient(180deg, var(--warning) 25%, rgba(218, 241, 217, 0) 70%);
}

.complete
{
    background: linear-gradient(180deg, var(--success) 25%, rgba(218, 241, 217, 0) 70%);
}

.unknown
{
    background: transparent;
}


@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .card_container
    {
        flex: 1;
    }

    .card_container:last-child
    {
        flex: 0.43;
    }
}

.day_modal_container
{
    display: flex;
    flex-direction: column;
    padding: 1rem;

    box-sizing: border-box;

    width: 1000px;

    gap: 30px;
    cursor: default;

    color: #000;
}

.holiday
{
    color: #ff0800;
}

.top
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.top svg
{
    width: 31px;
    height: 31px;
}

.top span
{
    font-size: 31px;
    font-weight: 600;
}

.center
{
    display: flex;
    text-align: left;
    width: 100%;
    height: 415px;
    gap: 10px;
}

.center textarea
{
    height: 150px;
}

.shifts_notes_comments_container
{
    display: flex;
    flex-direction: column;

    width: 50%;
    gap: 5px;
}

.user_shift
{
    display: flex;

    padding: 5px;
    box-sizing: border-box;

    border: 1px solid ;
    border-radius: 8px;

    color: #fff;
}

.text_editor_container
{
    display: flex;
    position: relative;

    width: 100%;
    height: 100%;
}

.text_editor
{
    position: absolute;
    width: 100%;
    height: 115px;
}

.comments, .notes
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;
    height: 50%;
}

.shifts_container
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 50%;
    height: 100%;
}

.shifts_list
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;
    height: 100%;

    overflow-y: scroll;

    padding: 8px;
    box-sizing: border-box;

    border: 1px solid var(--borderGrey);
    border-radius: 8px;
}

.shifts_list p
{
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
}

.comments_container
{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;

    border: 1px solid;
}

.close
{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;

    border: 2px solid transparent;
}

.close:hover
{
    border: 2px solid var(--btnColor);
    border-radius: 5px;
}

.close:active
{
    background: rgba(58, 169, 53, 0.10);
}

.morning
{
    background: var(--morning);
    border-color: var(--morning);
}

.day
{
    background: var(--day);
    border-color: var(--day);
}

.evening
{
    background: var(--evening);
    border-color: var(--evening);
}

.custom
{
    background: var(--custom);
    border-color: var(--custom);
}
.weekend_check
{
    background: linear-gradient(180deg, rgba(245,166,139,1) 20%, rgba(90,90,128,1) 80%);
}

.modal_controls
{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .day_modal_container
    {
        width: fit-content;
    }

    .center
    {
        flex-direction: column;
        overflow-y: scroll;
    }

    .shifts_container, .shifts_notes_comments_container
    {
        width: 100%;
    }

    .modal_controls
    {
        justify-content: center;
    }

    .top
    {
        text-align: center;
        align-items: flex-start;
        position: relative;
    }

    .comments, .notes
    {
        height: 250px;
    }

    .close
    {
        position: absolute;
        left: 90%;
    }
}
